.icon {
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  outline: 0;
}

.micButton {
  --primary-color: #0c5388;
  --secondary-color: #1fd041;
  --Listening-color: red;
  border: 0;
  background: transparent;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  margin-top: 0.1rem;
}

.listening {
  color: var(--Listening-color) !important;
}
.notListening {
  color: var(--primary-color) !important;
}

.boxContainer {
  display: flex;
  justify-content: space-between;
  height: 20px;
  --boxSize: 2px;
  --gutter: 4px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);
}

.box {
  transform: scaleY(0.4);
  height: 100%;
  width: var(--boxSize);
  background-color: var(--secondary-color);
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 8px;
}

.box1 {
  animation-name: quiet;
}

.box2 {
  animation-name: normal;
}

.box3 {
  animation-name: quiet;
}

.box4 {
  animation-name: loud;
}

.box5 {
  animation-name: quiet;
}

@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.6);
  }
}

@keyframes loud {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(1.2);
  }
}

.transcript {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4rem;
  font-weight: 700;
  color: #141414;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
  padding: 1rem;
  text-align: center;
  background-color: aliceblues;
  background-color: transparent;
  outline: none;
  width: 50%;
  background-color: #6b6a6a;
  border-radius: '0.9rem', /*     bg-slate-100 */;
  -webkit-border-radius: '0.9rem', /*     bg-slate-100 */;
  -moz-border-radius: '0.9rem', /*     bg-slate-100 */;
  -ms-border-radius: '0.9rem', /*     bg-slate-100 */;
  -o-border-radius: '0.9rem', /*     bg-slate-100 */;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.correctness {
  font-size: 1rem;
  font-weight: 500;
  margin: auto;
  text-align: center;
  padding: 1rem;
  font-size: 1.5rem;
  width: 50%;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.btnPrimary {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 50%;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  background-color: #90cdf4;
  color: #ffffff;
  opacity: 1; /* You might want to adjust this value */
}

.scale-image {

    &:hover {
        transform: scale(1.2);
        transition: transform 0.5s;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        margin-top: 1.5rem;


}

}

@keyframes soundWaves {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.7);
  }
}

// Colors
$color-primary: #263238;
$color-secondary: #43a047;
$color-accent: cornflowerblue;
$color-text: #fff;
$color-text-secondary: #607d8b;

// Fonts
$font-primary: 'Open Sans', sans-serif;

// Sizes
$chat-window-width: 600px;
$chat-window-height: 520px;
$font-size-base: 16px;
$font-size-header: 18px;
$font-size-message: 17px;
$font-size-meta: 14px;

// Chat Window
.chat-window {
  .chat-header {
    background: $color-primary;
    border-radius: 6px;
    // cursor: unset;
    position: relative;

    p {
      color: $color-text;
      display: inline-block;
      font-size: $font-size-header;
      font-weight: 700;
      line-height: 45px;
      padding: 0 1em 0 2em;
    }

    .view-quiz-button {
      border-radius: 6px;
      display: inline-block;
      font-size: $font-size-header;
      font-weight: 700;
      cursor: pointer;
    }
    .question-number {
      color: $color-text;
      display: inline-block;
      font-weight: 400;
      padding: 0 1em;
    }
    .marks-as-read-button {
      border-radius: 6px;
      display: inline-block;
      font-size: $font-size-header;
      font-weight: 700;
      cursor: pointer;
    }
    .close-button {
      padding: 2%;
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }

  .chat-body {
    background: #fff;
    height: calc(#{$chat-window-height} - 115px);
    position: relative;

    .message-container::-webkit-scrollbar {
      display: none;
    }

    .message {
      display: flex;
      height: auto;
      padding: 0.5%;

      .message-content {
        align-items: center;
        background-color: $color-secondary;
        color: white;
        display: flex;
        height: auto;
        max-width: 95%;
        min-height: 70%;
        margin: 0 1.5%;
        overflow-wrap: break-word;
        padding: 0 2%;
        word-break: break-word;

        p {
          font-size: $font-size-message;
          width: fit-content;
        }
      }

      &.you {
        justify-content: flex-start;
        margin-right: 15%;

        .message-content {
          justify-content: flex-start;
          border-radius: 0 5px 5px 5px;
        }

        .message-meta {
          justify-content: flex-start;

          #author,
          #time {
            font-size: $font-size-meta;
            font-weight: bold;
            margin-left: 10px;
          }
        }
      }

      &.other {
        justify-content: flex-end;
        margin-left: 15%;

        .message-content {
          background-color: $color-accent;
          border-radius: 5px 0 5px 5px;
          width: auto !important;
        }

        .message-meta {
          justify-content: flex-end;
          margin-right: 5px;

          #author,
          #time {
            font-size: $font-size-meta;
            font-weight: bold;
            margin-right: 10px;
          }
        }
      }

      .message-meta {
        display: flex;
        margin-left: 5px;
      }
    }
  }
}

.chat-body-container {
  border: 1px solid $color-primary;
  max-height: 77%;
  overflow-y: auto;
}

.hide {
  opacity: 0 !important;
}

///////////////////

.chat-box-footer {
  border-top: solid 1px #cfcfcf;
  box-sizing: border-box;
  display: flex;
  padding: 1.5% 2%;
  width: 100%;

  .chat-input {
    background-color: #d2d2d2;
    border: none;
    border-radius: 13px;
    box-sizing: border-box;
    color: #2f2f2f;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    height: 40px;
    max-height: 120px;
    padding: 10px 15px;
    resize: none;
    transition: 0.3s background-color;
    width: 100%;
    &:focus {
      background-color: #d3d3d3b6;
    }
  }
  .send-button {
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 10px;
    padding: 5px;

    svg {
      height: 16px;
      width: 16px;
    }
  }
}

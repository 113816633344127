.vdo-container {
  width: 100%;
  height: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 20px 7px rgb(0 0 0 / 5%);
  position: relative;
  z-index: 0;
}

.subtitles {
  position: absolute;
  bottom: 15%;
  left: 15%;
}

.subtitle-word {
  margin-right: 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem;
  background-color: transparent;
  outline: none;
}

.subtitle-word:hover {
  color: #f1f1f1;
  font-weight: 800;
  text-decoration: underline;
}

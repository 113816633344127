$maxHeight: 90vh;

.parents-layout {
  display: flex;
  flex-direction: column;
  height: max(100vh);
  gap: 0.2rem;
  overflow: hidden;
  .parents-nav {
    display: flex;
    min-height: 0;
    margin: 0;
  }
  .parents-swappable-view {
    flex: auto;
  }
  .quiz-grid {
    flex: 1;
    display: grid;
    align-content: center;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: fit-content(100%);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    padding: 1rem;
  }
  .quiz-card {
    width: 400px;
    display: flex;
    flex-direction: column;
  }
}
